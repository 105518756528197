<template>
    <NoButtonModal :open="open" @close="$emit('cancel')">
        <button class="close-corner-x" type="button" @click.prevent="alertResponse('cancel')">
            <span class="material-icons-outlined dark-text">close</span>
        </button>
        <h1>Delete Alert</h1>
        <p>Are you sure you want to delete {{ photosCount }} photo(s)?</p>
        <button @click="alertResponse('cancel')" class="button secondary">Cancel</button>
        <button @click="alertResponse('handleDelete')" class="button primary">Delete</button>
    </NoButtonModal>
</template>

<script>
import NoButtonModal from '@/components/general/modals/NoButtonModal';
export default {
    name: 'DeletePhotosAlert',
    components: { NoButtonModal },
    props: {
        open: {
            type: Boolean,
            required: true,
        },
        photosCount: {
            type: String,
            required: true
        }
    },
    methods: {
        alertResponse(action) {
            if (action === 'handleDelete') {
                this.$emit('handleDelete');
            }
            else {
                this.$emit('cancel');
            }
        }
    }
};
</script>
