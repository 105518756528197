<template>
    <ScrollWrapper :list="photoList" :customClass="'photos-list-container'"
     @reachedEndOfList="fetchMorePhotos()">
        <template #item="img">
            <PhotoCard :img="img" @selected="(val) => { emitSelection(val) }" />
        </template>
    </ScrollWrapper>
</template>

<script>
import ScrollWrapper from '../scroll-wrapper/ScrollWrapper.vue'
import PhotoCard from './PhotoCard.vue'
export default {
    name: 'PhotosListing',
    components: { PhotoCard, ScrollWrapper },
    data() {
        return {
            slide: 0,
            sliding: false,
        }
    },
    props: {
        photoList: {
            type: Array
        }
    },
    methods: {
        emitSelection(val) {
            this.$emit("photoSelected", { ...val })
        },
        fetchMorePhotos(){
            this.$emit('fetchMorePhotos')
        }
    },
}
</script>