<template>
    <div class="photos-page">
        <div class="photos-page-header">
            <MemberChangeDropdown :page="page" @action="goToPage" :currentClientId="currentClientId" @back="back()" />
            <div class="photos-header-right-buttons" v-if="!isPhotoListEmpty">
                <div class="select-all-photos">
                    <input type="checkbox" v-model="selectAllPhotos" id="selectAllPhotos" @click="selectAllHandler()" />
                    <label>Select All</label>
                </div>
                <button class="download" @click="downloadPhotos()"><span class="material-icons-outlined">
                        file_download
                    </span>
                    <span>
                        Download
                    </span>
                </button>
                <button class="delete" @click="deletePhotos()">
                    <span class="material-symbols-outlined">
                        delete
                    </span>
                    <span>
                        Delete</span></button>
            </div>
        </div>
        <div class="photos-page-hero-component">
            <div v-if="photoListRaw.length > 0" class="photos-page-photo-list">
                <PhotosListing :photoList="photoListRaw" @photoSelected="photoSelected"
                    @fetchMorePhotos="fetchNextSetOfPhotos()" />
            </div>
            <div v-else class="no-photos-heading">
                <h3>No Photos Shared by member</h3>
            </div>
        </div>
        <Loading v-if="loading == true" />
        <div class="expanded-view">
            <b-modal id="client-photos-modal" centered v-model="showModal" hide-footer hide-header>
                <div class="photo-modal-content" style="display: flex;">
                    <div class="previous-image" @click="imageNavigationHandler('left')"
                        :style="imageUrlForModalIndex === 0 ? `display:none` : ``">
                        <b-tooltip target="prev-image-icon" placement="left" title="Previous"></b-tooltip>
                        <span class="material-icons" id="prev-image-icon">
                            arrow_back_ios
                        </span>
                    </div>
                    <div class="modal-image-container">
                        <img :src="imageUrlForModal" />
                    </div>
                    <div class="photo-modal-close-button" @click="showModal = false">
                        <button>
                            <b-tooltip target="close-image-icon" placement="top" title="Close"></b-tooltip>
                            <span class="material-icons-outlined" id="close-image-icon">
                                close
                            </span>
                        </button>
                    </div>
                    <div class="next-image" @click="imageNavigationHandler('right')"
                        :style="imageUrlForModalIndex === this.photoListRaw.length - 1 ? `display:none` : ``">
                        <b-tooltip target="next-image-icon" placement="right" title="Next"></b-tooltip>
                        <span class="material-icons" id="next-image-icon">
                            arrow_forward_ios
                        </span>
                    </div>
                </div>
            </b-modal>
        </div>
        <DeletePhotosAlert v-if="showDeleteAlert" :open="showDeleteAlert" :photosCount="selectedPhotosCount"
            @cancel="showDeleteAlert = false" @handleDelete="handleDelete">
        </DeletePhotosAlert>
    </div>
</template>

<script>
import PhotosListing from '../../components/photos/PhotosListing.vue'
import DeletePhotosAlert from '../../components/photos/DeletePhotosAlert.vue'
import Loading from '@/components/general/loading/loading.vue';
import MemberChangeDropdown from '../../components/general/dropdownButtons/MemberChangeDropdown.vue'
import { getContactDetailsService } from '@/util/helpers/graphql/common.graphql.js'
import { getSharedPhotosForCounselorService, downloadPhotosService, deleteMemoriesService } from '@/util/helpers/graphql/photos.graphql.js'
export default {
    name: 'Photos',
    components: { PhotosListing, MemberChangeDropdown, Loading, DeletePhotosAlert },
    data() {
        return {
            clientInfo: null,
            loading: false,
            selectAllPhotos: false,
            slide: 0,
            sliding: false.valueOf,
            currentClientId: this.$route.params.client_id,
            imageIdForModal: null,
            showModal: false,
            limit: 100,
            nextToken: null,
            page: {
                label: 'Client Photos',
                name: 'ClientPhotos',
            },
            photoListRaw: [],
            showDeleteAlert: false,
            clientContactId: null,
            clientFamilyId: null,
            imageUrlForModalIndex: 0
        }
    },
    methods: {
        async handleDelete() {
            try {
                let selectedMediaIds = this.photoListRaw.filter(item => item.selected).map(item => item.mediaId)
                await deleteMemoriesService(this.photoListRaw[0].contactId, selectedMediaIds)
                this.$toasted.success(`Deleted ${this.selectedPhotosCount} photo(s) successfully!`)
                this.photoListRaw = this.photoListRaw.filter(item => !selectedMediaIds.includes(item.mediaId))
                this.showDeleteAlert = false
            }
            catch (err) {
                this.$toasted.error(`Failed to delete photo(s)`)
            }
        },
        imageNavigationHandler(direction) {

            if (direction === 'left') {
                if (this.imageUrlForModalIndex !== 0)
                    // return
                    // else
                    this.imageUrlForModalIndex -= 1
            }
            else {
                if (this.imageUrlForModalIndex < this.photoListRaw.length - 1) {
                    this.imageUrlForModalIndex += 1
                }
            }
        },
        fetchNextSetOfPhotos() {
            if (this.loading == false && this.nextToken !== null) {
                this.fetchSharedMemories()
            }
        },
        selectAllHandler() {
            this.selectAllPhotos = !this.selectAllPhotos
            this.photoListRaw = this.photoListRaw.map((item,) => ({
                ...item, selected: this.selectAllPhotos
            }))
        },
        photoSelected(val) {
            if (val.type == 'Modal') {
                this.imageUrlForModalIndex = this.photoListRaw.findIndex(item => item.id === val.id)
                this.showModal = true
            }
            else {
                let selectedIndex = this.photoListRaw.findIndex(item => item.id === val.id)
                if (selectedIndex > -1) {
                    this.photoListRaw[selectedIndex].selected = !this.photoListRaw[selectedIndex].selected
                }
                let selectedPhotosCount = this.photoListRaw.filter(item => item.selected).length
                if (selectedPhotosCount === this.photoListRaw.length)
                    this.selectAllPhotos = true
                else{
                    this.selectAllPhotos = false
                }
            }
        },
        back() {
            this.$router.push({
                label: 'ClientOverview',
                name: 'ClientOverview', "params": { "record_id": this.$route.params.client_id }
            });

        },
        async goToPage({ page, selectedClient }) {
            this.clientInfo = selectedClient
            this.nextToken = null
            this.photoListRaw = []
            await this.fetchContactDetails()
            await this.fetchSharedMemories();
            this.$router.push(page)
        },
        async fetchSharedMemories() {
            this.loading = true
            let sharedPhotos = await getSharedPhotosForCounselorService(this.clientFamilyId, this.clientContactId, this.limit, this.nextToken)
            if (sharedPhotos.items.length > 0) {
                this.photoListRaw = [...this.photoListRaw,
                ...sharedPhotos.items.map((item) => ({
                    id: item.mediaId + new Date().toISOString(),
                    mediaId: item.mediaId,
                    bucket: item.bucket,
                    key: item.key,
                    imgUrl: item.url,
                    contactId: item.contactId,
                    selected: false
                })),
                ]
            }
            this.nextToken = sharedPhotos.nextToken
            this.loading = false
        },
        async downloadPhotos() {
            let selectedPhotos = this.photoListRaw.filter(item => item.selected)
            if (selectedPhotos.length == 0) {
                this.$toasted.error('Please select photo(s) to download!');
            }
            else if (selectedPhotos.length == 1) {
                window.open(selectedPhotos[0].imgUrl, "_blank")
            }
            else {
                let memories = selectedPhotos.map(el => el.key)
                let bucket = selectedPhotos[0].bucket
                let downloadMemories = await downloadPhotosService(bucket, memories)
                window.open(downloadMemories.url, "_blank")
            }
        },
        async deletePhotos() {
            if (this.selectedPhotosCount === 0) {
                this.$toasted.error('Please select photo(s) to delete!')
            }
            else {
                this.showDeleteAlert = true
            }
        },
        async fetchContactDetails() {
            this.loading = true
            let res = await getContactDetailsService(this.clientInfo.email)
            this.clientContactId = res?.contactId
            this.clientFamilyId = res?.familyId
            this.loading = false
        }
    },
    computed: {
        isPhotoListEmpty() {
            return this.photoListRaw.length === 0
        },
        selectedPhotosCount() {
            return this.photoListRaw.filter(item => item.selected).length
        },
        imageUrlForModal() {
            return this.photoListRaw.length > 0 ? this.photoListRaw[this.imageUrlForModalIndex].imgUrl : "No photos"
        }
    }
}
</script>
