<template>
    <div :class="classNames" id="scroll-wrapper-parent">
        <slot v-for="item in list" name="item" v-bind="item"></slot>
        <div id="scroll-wrapper-parent-last-item-in-list">
            <h1>
            </h1>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ScrollWrapper',
    data() {
        return {
            classNames: "photos-list-container scroll-wrapper-container"
        }
    },
    props: {
        list: {
            type: Array
        }
    },
    methods: {
        emitEvent() {
            this.$emit("reachedEndOfList")
        }
    },
    // },
    mounted() {
        let parent = document.getElementById('scroll-wrapper-parent')
        let lastItem = document.getElementById('scroll-wrapper-parent-last-item-in-list')
        let {
            bottom: bottom1,
        } = parent.getBoundingClientRect()
        let {
            bottom: bottom2,
        } = lastItem.getBoundingClientRect()
        if (bottom2+10 <= bottom1) {
            console.log("emit refetch event")
            this.emitEvent()
        }
        parent.addEventListener('scroll', e => {
            let {
                bottom: bottom1,
            } = parent.getBoundingClientRect()
            let {
                bottom: bottom2,
            } = lastItem.getBoundingClientRect()
            if (bottom2+10 <= bottom1) {
                this.emitEvent()
            }
        })
    }


}
</script>