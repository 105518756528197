<template>
    <div class="photo-item">
        <div class="photo-card" style="">
            <div class="photo-image-container">
                <img :src="img.imgUrl" alt="photo shared by user" />
            </div>
            <div class="photo-select-checkbox" style="">
                <div v-if="!img.selected" class="placeholder-checkbox" @click="toggleSelect(img)"></div>
                <input v-else type="checkbox" @click="toggleSelect(img)" v-model="img.selected"/>
            </div>
            <div class="photo-enlarge-container" style="" @click="clickHandler(img)">
               <span class="material-symbols-outlined photos-enlarge-span">
                   drag_pan
               </span>
              
           </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PhotoCard',
    props: {
        imgUrl: {
            type: String
        },
        img: {
            type: Object
        }
    },
    methods: {
        toggleSelect(img){
            this.$emit('selected',{id:img.id,type:"checkbox"})
        },

        clickHandler(img){
            this.$emit('selected',{id:img.id,type:"Modal"})
        },
    },
}
</script>